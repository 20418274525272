import {model3data} from "@/data_mock/model3.data.js"
import {songdata} from "@/data_mock/song.data.js"
const state = {
    carList:[
        {
            "name":"特斯拉Models-2023款双电机全轮驱动版",
            "params":model3data,
            "images":[
                {id:1,"prompt":"外观正面",src:'https://cdn.weippstore.com/demo/20221122221542503821968520107.jpeg'},
                {id:2,"prompt":"外观尾部",src:'https://cdn.weippstore.com/demo/20221122221542308517890715601.jpeg'},
                {id:3,"prompt":"内饰中控台",src:'https://cdn.weippstore.com/demo/20221122221925056441335851221.jpeg'},
                {id:4,"prompt":"外观行李箱",src:'https://cdn.weippstore.com/demo/20221122221951242771056926114.jpeg'},
            ]

        },
        {
            "name":"亚迪-宋PLUS DM",
            "params":songdata,
            "images":[
                {id:1,"prompt":"外观正面",src:'https://cdn.weippstore.com/demo/20220314114816589676057469507.jpeg'},
                {id:2,"prompt":"外观尾部",src:'https://cdn.weippstore.com/demo/20220314114816779764965521756.jpeg'},
                {id:3,"prompt":"内饰中控台",src:'https://cdn.weippstore.com/demo/20220314115045529130096541715.jpeg'},
                {id:4,"prompt":"外观行李箱",src:'https://cdn.weippstore.com/demo/20220314114936882913021123522.jpeg'},
            ]
        }
    ],
    prompt_tmplate:"{params}\n根据以上汽车{name}参数{user_prompt}要求内容真实有效1000字左右段落使用<p></p>标签包裹"+
    "图片用标签[img id='<图片id>']表示下表数据选择图片其中prompt表示图片内容描述\n{images}",
    
}

const mutations = {

}

const actions = {
   
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}