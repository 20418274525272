import { getToken } from '@/utils/auth'


export function completion(data) {//创建worksummary
    return new Promise((reslove,reject)=>{
        let url = `/gpt/completion?token=${getToken()}`
        const ws = new WebSocket(process.env.VUE_APP_BASE_WS_API+url);
        ws.onopen = function (){//发送信息
            ws.send(JSON.stringify(data))
            reslove(ws)
        }
        ws.onerror = function(error){
            reject(error)
        }
    })
}
