import { get_user_info, get_email_verify_code } from "@/api/auth"
import { setToken, getToken, removeToken } from "@/utils/auth"
import { Base64 } from 'js-base64'
const state = {
    user_info: undefined
}

const getters = {
    UserPackage(state) {
        return state.user_info['user_packages']
    },
    UserVipPackage(state) {//vip包
        return state.user_info['user_packages'].filter((v, i) => {
            return v['package_type'] == 2
        })

    },
    UserAddPackage(state) {//加量包
        return state.user_info['user_packages'].filter((v, i) => {
            return v['package_type'] == 255
        })
    }
}

const mutations = {

    SET_USER_INFO(state, data) {
        if (data['nickname']) {
            data['nickname'] = Base64.decode(data['nickname'])
        }
        state.user_info = data
    }
}

const actions = {
    
    getUserInfo({ commit }, token) {
        if (token) {
            setToken(token)
        }
        return new Promise((reslove, reject) => {
            let token = getToken()
            if (!token) {
                reject()
            }
            get_user_info().then(({ data }) => {
                commit('SET_USER_INFO', data)
                reslove(data)

            }).catch(reject)

        })
    },
    logOut({ commit }) {//退出登陆
        console.log('logout')
        removeToken()
        commit('SET_USER_INFO', {})
        window.location.href="/"
    },
    getEmailVerifyCode({ state }, email) {//发送验证码
        return new Promise((reslove, reject) => {
            //先验证一下是否存在未过期的的
            let email_verify_code = localStorage.getItem('email_verify_code')
            if (email_verify_code) {
                email_verify_code = JSON.parse(email_verify_code)
                let exp = new Date(email_verify_code['exp'])
                let now = new Date()
                if (now < exp) {
                    
                    reslove(email_verify_code)
                    return null
                }
            }
            console.log('runsend')
            get_email_verify_code(email).then(({ data }) => {//
                // let {token,exp} = data
                localStorage.setItem('email_verify_code', JSON.stringify(data))
                reslove(data)
            }).catch(reject)

        })

    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}