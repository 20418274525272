import { completion } from "@/api/gpt_work"
import { Message, Confirm, MessageBox } from "element-ui"
import router from '@/router'
import { removeToken } from '@/utils/auth'
const state = {
    ChatStack: [],//对话栈
    instance: [
        //一些例子
        "say this is a test",
        "帮我写一篇《超验骇客》的影评吧",
        "将以下内容翻译成英语：\n没有伟大的品格，就没有伟大的人，甚至也没有伟大的艺术家，伟大的行动者。—— 罗曼·罗兰",
        "给这个商品写一篇推荐:产品 4/4 小提琴，云杉面板，乌木指板，背侧板材质：枫木，适用场景：演奏 专业级 教学培训 售价：19800",
        "创建10个虚拟人物信息，包含<姓名>|<年龄>|<性别>|<出生时间:格式 %Y-%m-%d>",
        "使用Javascript写二叉树的中序遍历",
        "写一篇论文综述，题目:《秦始皇修建长城是为了与外星人建立联系》",
    ],

}
const mutations = {

}
// {code:50000,detail:错误信息,前端展示, desc:未知错误}
// {code:50008,detail:token过期或帐号不可用，请联系管理员,desc:帐号无效}
// {code:50010,detail:'字数余额不足，请充值或购买新的账号,desc:余额不足}
// {code:50100,detail:错误信息,前端展示, desc:prompt为空}
// {code:50200,detail:输入命令生成失败，请重试}
const actions = {
    completion({ state, commit }, { data, onmessage }) {
        return new Promise((reslove, reject) => {
            completion(data).then(ws => {
                ws.onmessage = function ({ data }) {
                    data = JSON.parse(data)
                    let code = data['code']
                    data = data['data']
                    if ([20600, 20000].indexOf(code) > -1 && data) {//正常情况交给前端展示
                        onmessage(data['text'])
                    }else if(code==50101){//违规内容
                        Message.error("存在敏感内容，请修改！！")
                        
                    }else if (code === 50008) {//重新登录
                        MessageBox.confirm("您还未登陆！是否前往登陆？",'提示').then(cfm => {
                            removeToken()
                            window.location.href = '/'
                        })
                        
                    } else if (code === 50010) {
                        MessageBox.confirm("您的问答次数已用尽！", '提示',{
                            confirmButtonText: '果断充值',
                            cancelButtonText: '残忍放弃',
                            type: 'warning'
                        }).then(cfm => {//跳转到充值页面
                            // console.log(cfm,'this ok')
                            reslove({code,data})
                        }).catch(reject)
                    } else if(code==42900){//服务繁忙
                        Message.info("当前服务繁忙，请稍后再试")
                    }else if([206, 200].indexOf(code) > -1){//会话完成
                        reslove({code,data})
                    }else if(code>=50000){//意外错误
                        reject(code)
                    }
                }
            })
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}