import Vue from 'vue'
import VueRouter from 'vue-router'
import { isPC } from "@/utils/libs"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: () => {
      // 根据设备的不同进行跳转
      return isPC() ? '/pc' : '/mobile'
    }
  },
  {
    path:"/wmp-login",
    component: () => import('@/views/mobile/WmpLongin.vue'),
  },
  {
    path: '/pc',
    name: 'pc',
    component: () => import('@/views/pc/index.vue'),
    redirect: () => {
      // 根据设备的不同进行跳转
      return "/pc/WriteAssistant"
    },
    children: [
      {
        path: 'UserCenter', name: 'PcUserCenter',
        component: () => import('@/views/pc/UserCenterMain.vue'),
        meta: { title: '个人中心', icon: 'el-icon-s-custom',show4nav:"user-center"}
      },
      {
        path: 'PcImageVariation', name: 'PcImageVariation',
        component: () => import('@/views/pc/ImageVariation.vue'),
        meta: { title: '图片变换', icon: 'el-icon-picture-outline',show4nav:false}
      },
      {
        path: 'WriteAssistant', name: 'PcWriteAssistant',
        component: () => import('@/views/pc/WriteAssistant.vue'),
        meta: { title: '写作助手', icon: 'el-icon-s-promotion',show4nav:"nav"}
      },
      
      
      {
        path: 'Recharge', name: 'PcRecharge',
        component: () => import('@/views/pc/Recharge.vue'),
        meta: { title: '充值', icon: 'el-icon-s-shop',show4nav:"user-center"}
      },
      
    ],
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('@/views/mobile/index.vue'),
    redirect: () => {
      // 根据设备的不同进行跳转
      return "/mobile/WriteAssistant"
    },
    children: [
      {
        path: 'UserCenter', name: 'UserCenter',
        component: () => import('@/views/mobile/UserCenter.vue'),
        meta: { title: '个人中心', icon: 'el-icon-s-custom',show4nav:true}
      },
      {
        path: 'WriteAssistant', name: 'WriteAssistant',
        component: () => import('@/views/mobile/WriteAssistant.vue'),
        meta: { title: '写作助手', icon: 'el-icon-s-promotion',show4nav:true}
      },
      {
        path: 'CarWriter', name: 'CarWriter',
        component: () => import('@/views/mobile/CarWriter.vue'),
        meta: { title: '汽车软文', icon: 'el-icon-edit',show4nav:true}
      },
      {
        path: 'Recharge', name: 'Recharge',
        component: () => import('@/views/mobile/Recharge.vue'),
        meta: { title: '充值', icon: 'el-icon-s-shop',show4nav:true}
      },
      {
        path: 'UpdateUser', name: 'UpdateUser',
        component: () => import('@/views/mobile/UpdateUser.vue'),
        meta: { title: '修改用户信息', icon: 'el-icon-s-shop',show4nav:false}
      },
      
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
