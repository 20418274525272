<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data(){
    return {
      message: ''
    }
  },
  mounted(){
    
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.message = '手机'
    } else {
      this.message = '电脑'
    }
  }
}
</script>